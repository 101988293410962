import React, { useState } from "react";
import './header.css'
import Add_Person from "./Add_Person";

const Header = () => {
    const [showAddPerson, setShowAddPerson] = useState("none")
    return (
        <div id="header">

            <div class='header-main'>
                <img id="logo" src="../images/logo.png" srcset="../images/logo.png 1x" />

                <div id="Support-Portal">
                    <span>Support Portal</span>
                </div>

            </div>
            <div class='name-logout'>
                <div id="Mohammed_Affan">
                    <span>Mohammed Affan</span>
                </div>
                <button class='user-btn-1' onClick={() => setShowAddPerson('Add')}>
                    <img id="user" src="../images/user.png" srcset="../images/user.png 1x" />
                </button>
                <button class='user-btn-1' >

                    <img id="setup-1" src="../images/computer.png" srcset="../images/computer.png 1x" />
                </button>

                <img onclick="application.goToTargetView(event)" id="logout" src="../images/logout.png" srcset="../images/logout.png 1x" />
            </div>
            <Add_Person display={showAddPerson} closeAddPerson={() => setShowAddPerson('none')} />
        </div>
    )
}
export default Header