import React, { useState } from "react";
import './faq.css'
import Software_Header from "./Software_Header";
import Header from "./Header";
import Software_Nav from "./Software_Nav";
import Footer from "./Footer";
import Add_FAQ from "./Add_FAQ";


const FAQ = () => {
    const[shwoAddFaq,setSHowFaq] =useState("none")

    return (
        <div id="faq">
            <Header />
            <Software_Header />
            <Software_Nav />
            <div class='faq-header-1'>
                <button class='btn-faq' onClick={() =>setSHowFaq("Add")}>
                    Add FAQ
                </button>
                <div>
                    <div class='faq-name-1'>
                        <span>
                            FAQ
                        </span>
                    </div>
                    <div class='need-help-1'>
                        <span>
                            Need Help?
                        </span>
                    </div>
                </div>
            </div>
            <div class='question-need-help-1'>
                <div class='need-help-main-1'>
                    <div class='need-help-2'>
                        <span>
                            Need Help?
                        </span>
                    </div>
                    <div>
                        <img id="need-help-arrow-down-1" src="../images/arrow-down.png" srcset="../images/arrow-down.png 1x" />
                        <img id="need-help-arrow-up-1" src="../images/arrow-up.png" srcset="../images/arrow-up.png 1x" />
                        <img id="need-help-eye-1" src="../images/eye-open.png" srcset="../images/eye-open.png 1x" />

                    </div>
                </div>
                <div class='need-help-answer-1'>
                    <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                </div>
            </div>
            <div class='question-need-help-1'>
                <div class='need-help-main-1'>
                    <div class='need-help-2'>
                        <span>
                            Need Help?
                        </span>
                    </div>
                    <div>
                        <img id="need-help-arrow-down-1" src="../images/arrow-down.png" srcset="../images/arrow-down.png 1x" />
                        <img id="need-help-arrow-up-1" src="../images/arrow-up.png" srcset="../images/arrow-up.png 1x" />
                        <img id="need-help-eye-1" src="../images/eye-hide.png" srcset="../images/eye-hide.png 1x" />

                    </div>
                </div>
                {/* <div class='need-help-answer-1'>
                    <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                </div> */}
            </div>
            <Footer />
            <Add_FAQ display={shwoAddFaq} closeAddFAQ={() =>setSHowFaq("none")}/>
        </div>
    )
}
export default FAQ