import React, { useEffect, useState } from "react";
import './packages.css'
import Header from "./Header";
import Software_Header from "./Software_Header";
import Packages_Text from "./Package_Text";
import Footer from "./Footer";
import Add_Features from "./Add_Features";
import Add_Package from "./Add_Package";

const Packages = () => {
    const [showAddFeatures, setShowAddFeatures] = useState("none")
    const [showAddPackage, setShowAddPackage] = useState("none")
    const [featuresData, setFeaturesData] = useState([])
    const [packagesData, setPackagesData] = useState([])


    useEffect(() => {
        getFeatures()
        getPackages()
    }, [])

    const getFeatures = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/features";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setFeaturesData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const getPackages = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/packages";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setPackagesData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }
    // console.log()
    return (
        <div id="packages">
            <Header />
            <Software_Header />
            <Packages_Text />
            <div class='addfeature-packages'>

                <div id="Packages_ev">
                    <span>Packages</span>
                </div>
                <select class="Rectangle_54">
                    <option>1 Month</option>
                    <option>3 Months</option>
                    <option>6 Months</option>
                    <option>1 Year</option>
                </select>
            </div>
            <div class='packages-types'>
                <button id="Group_74" onClick={() => setShowAddFeatures("Add")}>

                    <div id="Add_Feature">
                        <span>Add Feature</span>
                    </div>
                </button>
                <div class='packages-group-12'>
                    {packagesData.map((rec) =>
                        <div class='dlt-package-1'>
                            <img id="trash_1_gc" src="../images/delete.png" srcset="../images/delete.png 1x" />

                            <div id="Package_1">
                                <span>{rec.packageName}</span>
                            </div>
                        </div>
                    )}
                </div>
                <button id="add-package-grp" onClick={() => setShowAddPackage('Add')}>

                    <div id="Add_package-1">
                        <span>Add Package</span>
                    </div>
                </button>

            </div>
            <div class='scroll-packages-1'>
                {featuresData.map((rec) =>
                    <div class='package-selection'>
                        <img id="question-1" src="../images/question.png" srcset="../images/question.png 1x" />
                        <div class='features-mark-1'>
                            <div class='dlt-feature-1'>
                                <img id="dlt_1_gc" src="../images/delete.png" srcset="../images/delete.png 1x" />

                                <div id="feature-1">
                                    <span>{rec.feature}</span>
                                </div>
                            </div>

                            <div class='check-features-group'>
                                {packagesData.map((rew) =>
                                    <div class='check-feature-1'>
                                        {rew.featureIds.includes(rec._id) ?

                                            <img id="check-1" src="../images/check.png" srcset="../images/check.png 1x" />
                                            : ""}
                                    </div>
                                )}
                            </div>



                        </div>
                        <img id="arrow-down-1" src="../images/arrow-down.png" srcset="../images/arrow-down.png 1x" />
                        <img id="arrow-up-1" src="../images/arrow-up.png" srcset="../images/arrow-up.png 1x" />

                    </div>
                )}


            </div>
            <div class='cost-1'>
                <div class='cost-name-1'>
                    Cost
                </div>
                <div id="cost-n_01">
                    <span>10000$</span>
                </div>
                <div id="cost-n_02">
                    <span>10000$</span>
                </div>
                <div id="cost-n_03">
                    <span>10000$</span>
                </div>
                <div id="cost-n_04">
                    <span>10000$</span>
                </div>

                <div id="cost-n_05">
                    <span>10000$</span>
                </div>

            </div>
            <Footer />

            <Add_Features display={showAddFeatures} closeAddFeatures={() => setShowAddFeatures("none")} />
            <Add_Package display={showAddPackage} closeAddPackage={() => setShowAddPackage("none")} featuresData={featuresData} />

        </div>
    )
}

export default Packages