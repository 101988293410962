import React, { useContext, useEffect, useState } from "react";
import "./home.css"
import Footer from "./Footer";
import Header from "./Header";
import SoftwareCard from "./SoftwareCard";
import Add_Software from "./Add_Software";
import Add_Company from "./Add_Company";
import { Link } from "react-router-dom";


const Home = () => {
    const [showAddSoftware, setShowAddSoftware] = useState('none')
    const [companyData, setCompanyData] = useState([])


    useEffect(() => {
        getCompanies()
    }, [showAddSoftware])

    const getCompanies = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/companies";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setCompanyData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    return (
        <div id="Home">
            <Header />
            <div class='btn-software'>
                <button class='add-btn' onClick={() => setShowAddSoftware('Add')}>
                    <img src="../images/plus.png" class='img-btn' />
                </button>
                <div class='software-cards'>
                    {companyData.map((rec) =>
                       
                            <SoftwareCard data={rec} />
                    )}
                </div>
            </div>
            <Footer />
            {showAddSoftware !== 'none' && <Add_Company display={showAddSoftware} closeAddCompany={() => setShowAddSoftware('none')} />}
        </div>
    )
}

export default Home;