import './add_Package.css'
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch } from '@mui/material';
import Select from 'react-select';

const Add_Package = (props) => {
    const [active, setActive] = useState(true)
    const [packageName, setPackageName] = useState("")
    const [appId, setAppId] = useState("")
    const [featureIds, setFeatureIds] = useState([])
    const [packageAmount, setPackageAmount] = useState("")
    const [packageCurrency, setPackageCurrency] = useState("")
    const [packageDuration, setPackageDuration] = useState("")
    const [paymentDurationMonth, setPaymentDurationMonth] = useState("")
    const [paymentDurationYear, setPaymentDurationYear] = useState("")
    const [paymentFrequency, setPaymentFrequency] = useState("")
    const [packageMaxUsers, setPackageMaxUsers] = useState("")
    // const[,set] = useState("")
    const [appsData, setAppsData] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {
        if (props.display === "Edit") {
            setPackageName(props.data ? props.data.packageName : "")
            setAppId(props.data ? props.data.appId : "")
            setPackageAmount(props.data ? props.data.packageAmount : "")
            setPackageCurrency(props.data ? props.data.packageCurrency : "")
            setPaymentDurationMonth(props.data ? props.data.paymentDurationMonth : "")
            setPaymentDurationYear(props.data ? props.data.paymentDurationYear : "")
            setPaymentFrequency(props.data ? props.data.paymentFrequency : "")
            setPackageMaxUsers(props.data ? props.data.packageMaxUsers : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.display])
    
    useEffect(() => {
        getApps()
    }, [props])

    const getApps = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/apps";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setAppsData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    useEffect(() => {
        const selectedIds = selectedOptions.map(option => option.value);
        setFeatureIds(selectedIds)
    }, [featureIds])

    const options = props.featuresData.map((rec) => ({
        value: rec._id,
        label: rec.feature,
    }));

    const addPackage = () => {
        // if (email.length === 0) {
        //     return alert("Please Enter Email!!");
        // }

        const apiUrl = process.env.REACT_APP_API_KEY + '/packages';
        var apiParam = {
            method: 'POST',
            headers: {
                appId: appId,
                packagename: packageName,
                featureids: featureIds,
                packageamount: packageAmount,
                packagecurrency: packageCurrency,
                packageduration: (parseInt(paymentDurationYear) * 12) + parseInt(paymentDurationMonth),
                packagemaxUsers: packageMaxUsers,
                paymentfrequency: paymentFrequency,
                active: active,
            },
        };

        // console.log(apiUrl, apiParam, 'apiUrl', 'apiParam')

        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {

                if (repos.data) {
                    props.closeAddPackage(false)
                    alert("Package Added Successfully!!")

                } else alert(JSON.stringify('error'));
            })
            .catch((err) => alert(JSON.stringify(err)));
    };

    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddPackage(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1-Package'>
                    <div class='add-Package-1'>
                        <div class='add-Package-header-1'>
                            <div id="add-Package-name-1">
                                <span>Add Package</span>
                            </div>
                            <button class='close-btn-1' onClick={() => props.closeAddPackage(false)}>
                                <img id="add-Package-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='swicth-active-1-Package'>

                            <FormControlLabel control={<Switch
                                checked={active}
                                onChange={() => setActive(!active)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked />} label="Active" />

                        </div>
                        <div class='inputs-add-Package-group'>
                            <div class='Package-names-1'>
                                <div id="Package-name-header-1">
                                    <span>Package Name</span>
                                </div>
                                <input class='add-Package-name-input-1' placeholder='Package Name' value={packageName} onChange={(e) => setPackageName(e.target.value)} />
                            </div>
                            <div class='Package-names-2'>
                                <div id="Package-name-header-2">
                                    <span>App Name</span>
                                </div>
                                {/* <input class='add-Package-name-input-2' placeholder='App Name' /> */}
                                <select class='add-Package-name-input-2' value={appId} onChange={(e) => setAppId(e.target.value)}>
                                    <option value={''}>Select</option>
                                    {appsData.map((rec) =>
                                        <option value={rec._id}>{rec.appName}</option>
                                    )}

                                </select>
                            </div>
                            <div class='Package-names-3'>
                                <div id="Package-name-header-3">
                                    <span>Features</span>
                                </div>
                                {/* <select class='add-Package-name-input-3' multiple={true} value={selectedOptions} onChange={handleSelectChange}>
                                    <option value={''}>Select</option>
                                    {props.featuresData.map((rec) =>
                                        <option value={rec._id}>{rec.feature}</option>
                                    )}
                                </select> */}
                                <Select
                                    class='add-Package-name-input-3'
                                    isMulti
                                    value={selectedOptions}
                                    onChange={handleSelectChange}
                                    options={options}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            width: "22vw",
                                            height: "4vh",
                                            border: '1.8px solid rgb(9, 166, 228)',
                                            borderRadius: "7px",
                                            paddingLeft: "10px",
                                            // width: '300px',         // Set the width of the dropdown
                                            // minHeight: '50px',      // Set the minimum height of the dropdown
                                            // height: 'auto',         // Ensure height is auto to fit content
                                            // border: '1px solid gray',
                                            // boxShadow: state.isFocused ? '0 0 0 1px blue' : '0 0 0 1px gray',
                                            // borderRadius: '4px',
                                            // padding: '2px',         // Add padding to control
                                        }),
                                    }}
                                />
                            </div>
                            <div class='Package-Amount-Currency-group'>

                                <div class='Package-names-4'>
                                    <div id="Package-name-header-4">
                                        <span>Package Amount</span>
                                    </div>
                                    <input class='add-Package-name-input-4' placeholder='Package Amount' value={packageAmount} onChange={(e) => setPackageAmount(e.target.value)} />
                                </div>
                                <div class='Package-names-4'>
                                    <div id="Package-name-header-4">
                                        <span>Package Currency</span>
                                    </div>
                                    <input class='add-Package-name-input-4' placeholder='Package Currency' value={packageCurrency} onChange={(e) => setPackageCurrency(e.target.value)} />
                                </div>
                            </div>
                            <div class='Package-Amount-Currency-group'>

                                <div class='Package-names-4'>
                                    <div id="Package-name-header-4">
                                        <span>Package Duration</span>
                                    </div>
                                    <div class='add-month-year-Package-1'>
                                        <select class='add-Package-name-input-5' value={paymentDurationYear} onChange={(e) => setPaymentDurationYear(e.target.value)}>

                                            <option>1 Year</option>
                                            <option>2 Year</option>
                                            <option>3 Year</option>
                                            <option>4 Year</option>
                                            <option>5 Year</option>
                                            <option>6 Year</option>
                                            <option>7 Year</option>
                                            <option>8 Year</option>
                                            <option>9 Year</option>
                                            <option>10 Year</option>


                                        </select>
                                        <select class='add-Package-name-input-5' value={paymentDurationMonth} onChange={(e) => setPaymentDurationMonth(e.target.value)}>
                                            <option>1 Month</option>
                                            <option>2 Month</option>
                                            <option>3 Month</option>
                                            <option>4 Month</option>
                                            <option>5 Month</option>
                                            <option>6 Month</option>
                                            <option>7 Month</option>
                                            <option>8 Month</option>
                                            <option>9 Month</option>
                                            <option>10 Month</option>
                                            <option>11 Month</option>
                                            <option>12 Month</option>


                                        </select>
                                    </div>
                                </div>
                                <div class='Package-names-4'>
                                    <div id="Package-name-header-4">
                                        <span>Package Max Users</span>
                                    </div>
                                    <input class='add-Package-name-input-4' placeholder='Package Max Users' value={packageMaxUsers} onChange={(e) => setPackageMaxUsers(e.target.value)} />
                                </div>
                            </div>
                            <div class='Package-names-3'>
                                <div id="Package-name-header-3">
                                    <span>Payment Frequency</span>
                                </div>
                                <select class='add-Package-name-input-2' value={paymentFrequency} onChange={(e) => setPaymentFrequency(e.target.value)}>
                                    <option>Postpaid</option>
                                    <option>Monthly</option>
                                    <option>Quaterly</option>
                                    <option>Annualy</option>


                                </select>
                            </div>
                        </div>
                        <button class='add-btn-Package-1' onClick={() => addPackage()}>
                            {props.display}
                        </button>
                    </div>
                    {/* <Button onClick={() => props.closeAddPackage(false)}>Close</Button> */}
                </div>

            </Modal>
        </>
    )
}
export default Add_Package