import React, { useContext, useEffect, useState } from "react";
import './softwareCard.css'
import { Link } from "react-router-dom";
import Add_Company from "./Add_Company";
import { UserContext } from "../UserContext";
import { CompanyContext } from "./CompanyContext";

const SoftwareCard = (props) => {
    const [showAddCompany, setShowAddCompany] = useState("none")

    const { company, setCompany } = useContext(CompanyContext)

    const storeCompanyData = (data) => {
        localStorage.setItem("companyData", JSON.stringify(data));
        setCompany(data)
    }

    return (
        <>

            <div id="Group_35" onClick={() => storeCompanyData(props.data)}>
                <Link
                    to={{
                        pathname: '/SoftwarePage',
                    }}
                >
                    <img id="Screenshot_2023-09-28_150533_fy" src={props.data.companyLogoUrl || "../images/company.png"}  alt="" />

                    <svg class="Rectangle_31">
                        <rect id="Rectangle_31" rx="0" ry="0" x="0" y="0" width="202" height="34">
                        </rect>
                    </svg>
                    <div id="Operate_Live">
                        <span>{props.data.companyName}</span>
                    </div>

                </Link>
                <div id="Group_37" onClick={() => setShowAddCompany("Edit")}>
                    <svg class="Ellipse_1">
                        <ellipse id="Ellipse_1" rx="15" ry="15" cx="15" cy="15">
                        </ellipse>
                    </svg>
                    <img id="pen" src="../images/pen.png" srcset="../images/pen.png 1x" alt="" />

                </div>
            </div>
            <Add_Company display={showAddCompany} closeAddCompany={() => setShowAddCompany("none")} data={props.data} />
        </>
    )
}
export default SoftwareCard