import React from "react";

const Saudi_States = (props) => {
    return (
        <div>
            <select class="add-company-name-input-6" value={props.companyState} onChange={(e) => props.setCompanyState(e.target.value)}>
                <option value="Riyadh">Riyadh</option>
                <option value="Makkah">Makkah </option>
                <option value="Al-Madinah">Al-Madinah </option>
                <option value="Buraidah">Buraidah</option>
                <option value="Dammam">Dammam</option>
                <option value="Abha">Abha</option>
                <option value="Tabouk">Tabouk</option>
                <option value="Hayel">Hayel</option>
                <option value="Arar">Arar</option>
                <option value="Jazan">Jazan</option>
                <option value="Al-Baha">Al-Baha</option>
                <option value="Sakaka">Sakaka</option>
            </select>

        </div>
    )
}

export default Saudi_States