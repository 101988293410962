import React, { useState } from "react";
import './ticket.css'
import Software_Header from "./Software_Header";
import Header from "./Header";
import Software_Nav from "./Software_Nav";
import Footer from "./Footer";
import Add_Ticket from "./Add_Ticket";

const Ticket = () => {
    const [showAddTicket, setShowAddTicket] = useState("none")

    return (
        <div id="ticket">

            <Header />
            <Software_Header />
            <Software_Nav />
            <div class='ticket-main-1'>
                <div class='ticket-priority-1'>
                    <div class='all-ticket-1'>
                        <span>
                            All Tickets are Priority
                        </span>
                    </div>
                    <img id="graph-1" src="../images/graph.png" srcset="../images/graph.png 1x" />

                </div>
                <div class='ticket-priority-2'>
                    <div class='all-ticket-2'>
                        <span>
                            All Tickets are Status
                        </span>
                    </div>
                    <img id="graph-2" src="../images/graph.png" srcset="../images/graph.png 1x" />

                </div>
                <div class='ticket-priority-3'>
                    <div class='all-ticket-3'>
                        <span>
                            All Tickets by Status
                        </span>
                    </div>
                    <div>
                        <div class='open-ticket-1'>
                            <span>
                                Open
                            </span>
                        </div>
                        <div class='open-bar-1'>

                        </div>

                    </div>
                    <div>
                        <div class='open-ticket-1'>
                            <span>
                                Pending
                            </span>
                        </div>
                        <div class='open-bar-1'>

                        </div>

                    </div>
                    <div>
                        <div class='open-ticket-1'>
                            <span>
                                Closed
                            </span>
                        </div>
                        <div class='open-bar-1'>

                        </div>

                    </div>
                    <div>
                        <div class='open-ticket-1'>
                            <span>
                                Resolved
                            </span>
                        </div>
                        <div class='open-bar-1'>

                        </div>

                    </div>
                </div>
            </div>
            <div class='search-1'>
                <input placeholder="Search.." class='inpute-search-1' />
                <img id="search-img-1" src="../images/search.png" srcset="../images/search.png 1x" />

            </div>
            <div class='ticket-middle-1'>
                <button class='ticket-middel-btn' onClick={() => setShowAddTicket("Add")}>
                    <img id="ticket-middel-btn-img" src="../images/add3.png" srcset="../images/add3.png 1x" />

                </button>
                <div class='ticket-high-priority-1'>
                    <div class='ticket-high-priority-box-1'>

                    </div>
                    <div class='ticket-high-priority-name-count-1'>
                        <div class='ticket-high-priority-name-1'>
                            <span>
                                High Priority Tickets
                            </span>

                        </div>
                        <div class='ticket-high-priority-count-1'>
                            <span>
                                100
                            </span>

                        </div>
                    </div>
                </div>
                <div class='ticket-high-priority-2'>
                    <div class='ticket-high-priority-box-2'>

                    </div>
                    <div class='ticket-high-priority-name-count-2'>
                        <div class='ticket-high-priority-name-2'>
                            <span>
                                Overdue Tickets
                            </span>

                        </div>
                        <div class='ticket-high-priority-count-2'>
                            <span>
                                100
                            </span>

                        </div>
                    </div>
                </div>
                <div class='ticket-high-priority-3'>
                    <div class='ticket-high-priority-box-3'>

                    </div>
                    <div class='ticket-high-priority-name-count-3'>
                        <div class='ticket-high-priority-name-3'>
                            <span>
                                Unopened Tickets
                            </span>

                        </div>
                        <div class='ticket-high-priority-count-3'>
                            <span>
                                100
                            </span>

                        </div>
                    </div>
                </div>
                <div class='ticket-high-priority-4'>
                    <div class='ticket-high-priority-box-4'>

                    </div>
                    <div class='ticket-high-priority-name-count-4'>
                        <div class='ticket-high-priority-name-4'>
                            <span>
                                Active Tickets
                            </span>

                        </div>
                        <div class='ticket-high-priority-count-4'>
                            <span>
                                100
                            </span>

                        </div>
                    </div>
                </div>
            </div>
            <div class='ticket-bottom-1'>

            </div>
            <Footer />
            <Add_Ticket display={showAddTicket} closeAddTicket={() => setShowAddTicket("none")} />
        </div>
    )
}
export default Ticket