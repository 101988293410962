import React, { useEffect, useState } from "react";
import './software_Bottom.css'
import Add_Users from "./Add_Users";
import { Checkbox } from "@mui/material";

const Software_Bottom = () => {
    const [showAddUsers, setShowAddUsers] = useState("none")
    // https://spapis.operate.live/claims/66880cf7e8407df20f314ac9
    const [claimsData, SetClaimsData] = useState([])
    const [accessData, setAccessData] = useState([])
    const [sectionData, setSectionData] = useState([])


    useEffect(() => {
        getFeatures()
    }, [])

    const getFeatures = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/claims/66880cf7e8407df20f314ac9";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    SetClaimsData(repos.data)
                    setAccessData(JSON.parse(repos.data[0].access))
                    setSectionData(JSON.parse(repos.data[0].sections))


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }


    // console.log(access, 'access', sections, 'sections')



    return (
        <div id="Group_31">
            <div class='search-bar'>
                <div id="Users">
                    <span>Users</span>
                </div>
                <input placeholder="Search..." class="Rectangle_22" />

                <img id="search" src="search.png" srcset="../images/search.png 1x" />

                <div id="Group_22" onClick={() => setShowAddUsers("Add")}>

                    <img id="add_d" src="../images/add2.png" srcset="../images/add2.png 1x" />

                </div>
            </div>

            <div class='users-1'>
                <div id="name-1">
                    <span>Name</span>
                </div>
                <div id="loginId-1">
                    <span> Login ID  </span>
                </div>

                <div id="lastLogin-1">
                    <span> Last Login </span>
                </div>
                <div id="role-1">
                    <span>Role</span>
                </div>
                <div id="access-1">
                    <span> Access </span>
                </div>
                <div id="active-1">
                    <span> Active </span>
                </div>


            </div>

            <div id="Scroll-users-1">
                <div class='users-2'>
                    <div id="name-2">
                        <span>Name</span>
                    </div>
                    <div id="loginId-2">
                        <span> Login ID  </span>
                    </div>

                    <div id="lastLogin-2">
                        <span> Last Login </span>
                    </div>
                    <div id="role-2">
                        <span>Role</span>
                    </div>
                    <div id="access-2">
                        <span> Access </span>
                    </div>
                    <div id="active-2">
                        <span> Active </span>
                    </div>


                </div>
            </div>

            <div class='role-access-cards'>
                <div class="access-card-1">
                    <div class='access-main'>
                        <div id="access-name">
                            <span>Access</span>
                        </div>
                        <img id="access-plus" src="../images/add3.png" srcset="../images/add3.png 1x" />

                    </div>
                    <div class='access-main-1'>
                        <div id="access-name-1">
                            <span>Access</span>
                        </div>
                        <div id="access-id-1">
                            <span>Id</span>
                        </div>

                    </div>
                    <div class='access-scroll-1'>
                        {accessData.map((rec, Index) =>
                            <div class='access-main-2'>
                                <div id="access-name-2">
                                    {/* <span>{Index}</span> */}
                                    <span>{Object.keys(rec)[0]}</span>

                                </div>
                                <div id="access-id-2">
                                    <Checkbox
                                        checked={rec[Object.keys(rec)[0]]}
                                        // onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                </div>

                            </div>
                        )}
                    </div>
                </div>

                <div class="role-card-1">
                    <div class='role-main'>
                        <div id="roles-name">
                            <span>Sections</span>
                        </div>
                        <img id="roles-plus" src="../images/add3.png" srcset="../images/add3.png 1x" />

                    </div>
                    <div class='role-main-1'>
                        <div id="roles-name-1">
                            <span>Sections</span>
                        </div>
                        <div id="id-name-1">
                            <span>Id</span>
                        </div>

                    </div>
                    <div class='roles-scroll-1'>
                        {sectionData.map((rec) =>
                            <div class='role-main-2'>
                                <div id="roles-name-2">
                                    <span>{Object.keys(rec)[0]}</span>
                                </div>
                                <div id="id-name-2">
                                    <Checkbox
                                        checked={rec[Object.keys(rec)[0]]}
                                        // onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>

                            </div>
                        )}
                    </div>
                </div>


            </div>

            <Add_Users display={showAddUsers} closeAddUsers={() => setShowAddUsers("none")} />
        </div>
    )
}

export default Software_Bottom