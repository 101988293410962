import React, { useContext, useState } from "react"
import './Login.css'
import Footer from "./Footer"
import { Link, useNavigate } from "react-router-dom"
import { UserContext } from "../UserContext"

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const { user, setUser } = useContext(UserContext);

    // console.log(process.env.REACT_APP_API_KEY,'React_API')
    // const redirect = () => {
    //     if (email.length === 0) {
    //         return alert('Please Enter Email!!');
    //     }
    //     if (password.length === 0) {
    //         return alert('Please Enter Password!!');
    //     }
    //     const apiUrl = process.env.REACT_APP_API_KEY + '/users/login';
    //     const apiParam = {
    //         method: 'POST',
    //         headers: {
    //             email: email,
    //             password: password,
    //         },
    //         credentials: 'same-origin'
    //     };

    //     console.log(apiUrl, apiParam, 'apiUrl, apiParam')

    //     fetch(apiUrl, apiParam)
    //         .then((res) => (res.status === 200 ? res.json() : res.text()))
    //         .then((repos) => {
    //             console.log(repos, 'repos', '0')

    //             if (repos.data) {
    //                 console.log('1')

    //                 try {
    //                     console.log('2')

    //                     localStorage.setItem('loggedintoken', repos.data);
    //                     const user = JSON.parse(atob(repos.data.split('.')[1]));
    //                     localStorage.setItem('loggedInUser', JSON.stringify(user));
    //                     setUser(user);
    //                     console.log('3')

    //                     navigate('/Home');
    //                 } catch (err) {
    //                     console.log('4')

    //                     alert(err);

    //                 }
    //             } else {
    //                 console.log('5')

    //                 alert(JSON.stringify(repos));
    //             }
    //         })
    //         .catch((err) => console.log(JSON.stringify(err), '6'));
    // };

    const redirect = () => {
        if (email.length === 0) {
            return alert("Please Enter Email!!");
        }
        if (password.length === 0) {
            return alert("Please Enter Password!!");
        }
        const apiUrl = process.env.REACT_APP_API_KEY + '/users/login';
        var apiParam = {
            method: 'POST',
            headers: {
                username: email,
                password: password
            },
            credentials: 'same-origin'
        };

        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {

                if (repos.data) {
                    // console.log(repos.data, '2')
                    localStorage.setItem("loggedintoken", repos.data);
                    localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
                    setUser(JSON.parse(atob(repos.data.split(".")[1])))
                    //   setName(JSON.parse(atob(repos.data.split(".")[1])).name)
                    navigate("/Home");

                } else alert(JSON.stringify(repos));
            })
            .catch((err) => alert(JSON.stringify(err)));
    };

    return (
        <div id="login">
            <div id="Group_44">
                <img id="n_625451_2453278" src="../images/login-bg.png" srcset="../images/login-bg.png 1x, ../images/login-bg.png 2x" />

                <svg class="Rectangle_34">
                    <rect id="Rectangle_34" rx="22" ry="22" x="0" y="0" width="450" height="45">
                    </rect>
                </svg>
                <div id="Group_41">

                    <img id="logo_si" src="../images/logo.png" srcset="../images/logo.png 1x, ../images/logo.png 2x" />

                </div>
                <div id="Client_Technical_Support">
                    <span>Client Technical Support</span>
                </div>
            </div>
            <div id="Group_43">
                <img id="n_19225_OBDKVJ0" src="../images/pc.png" srcset="../images/pc.png 1x, ../images/pc.png 2x" />
                <div class='inputs'>
                    {/* <Link to={'/Home'}> */}
                    <button id="btn" onClick={() => redirect()}>

                        <div id="Sign_in">
                            <span>Sign in</span>
                        </div>
                    </button>
                    {/* </Link> */}
                    <input placeholder="Email" class="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input placeholder="Password" type='password' class="password" value={password} onChange={(e) => setPassword(e.target.value)} />

                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Login