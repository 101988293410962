import './add_Software.css'
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch } from '@mui/material';

const Add_Software = (props) => {
    const [active, setActive] = useState(true)

    const [appName, setAppName] = useState("")
    const [problemAddressed, setProblemAddressed] = useState("")
    const [solutionProvided, setSolutionProvided] = useState("")
    const [technicalSPOCName, setTechnicalSPOCName] = useState("")
    const [technicalSPOCMobile, setTechnicalSPOCMobile] = useState("")
    const [technicalSPOCEmail, setTechnicalSPOCEmail] = useState("")
    const [escalationSPOCName, setEscalationSPOCName] = useState("")
    const [escalationSPOCMobile, setEscalationSPOCMobile] = useState("")
    const [escalationSPOCEmail, setEscalationSPOCEmail] = useState("")


    const [differentiator, setDifferentiator] = useState("")
    const [callToAction, setCallToAction] = useState("")
    const [logoUrl, setLogoUrl] = useState("")
    const [ticketsUrl, seTticketsUrl] = useState("")
    const [supportEmails, setSupportEmails] = useState("")
    const [alertEmails, setAlertEmails] = useState("")
    const [supportMobileNumbers, setSupportMobileNumbers] = useState("")
    const [alertMobileNumbers, setAlertMobileNumbers] = useState("")
    const [responsiblePersonId, setResponsiblePersonId] = useState("")
    const [responsiblePersonName, setResponsiblePersonName] = useState("")
    const [responsiblePersonMobile, setResponsiblePersonMobile] = useState("")

    const [responsiblePersonEmail, setResponsiblePersonEmail] = useState("")

    const [accountablePersonId, setAccountablePersonId] = useState("")
    const [accountablePersonName, setAccountablePersonName] = useState("")
    const [accountablePersonMobile, setAccountablePersonMobile] = useState("")

    const [accountablePersonEmail, setAccountablePersonEmail] = useState("")

    const [consultedPersonId, setConsultedPersonId] = useState("")
    const [consultedPersonName, setConsultedPersonName] = useState("")
    const [consultedPersonMobile, setConsultedPersonMobile] = useState("")
    const [consultedPersonEmail, setConsultedPersonEmail] = useState("")

    const [informedPersonId, setInformedPersonId] = useState("")
    const [informedPersonName, setInformedPersonName] = useState("")
    const [informedPersonMobile, setInformedPersonMobile] = useState("")
    const [informedPersonEmail, setInformedPersonEmail] = useState("")
    const [selectApp, setSelectApp] = useState('')
    const [appsData, setAppsData] = useState([])

    useEffect(() => {

        getApps()

    }, [])



    const getApps = () => {
        // https://spapis.operate.live/company_apps/66880a3fe8407df20f314abb
        let apiURL = process.env.REACT_APP_API_KEY + "/apps";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setAppsData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const addCompany = () => {
        // if (email.length === 0) {
        //     return alert("Please Enter Email!!");
        // }

        const apiUrl = process.env.REACT_APP_API_KEY + '/apps';
        var apiParam = {
            method: 'POST',
            headers: {
                appname: appName,
                problemaddressed: problemAddressed,
                solutionprovided: solutionProvided,
                differentiator: differentiator,
                calltoaction: callToAction,
                logourl: logoUrl,
                ticketsurl: ticketsUrl,
                supportemails: supportEmails,
                alertemails: alertEmails,
                supportmobilenumbers: supportMobileNumbers,
                alertmobilenumbers: alertMobileNumbers,
                responsiblepersonId: responsiblePersonId,
                accountablepersonId: accountablePersonId,
                consultedpersonId: consultedPersonId,
                informedpersonId: informedPersonId,
                active: active,
            },
        };

        // console.log(apiUrl, apiParam, 'apiUrl', 'apiParam')

        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {

                if (repos.data) {

                    alert("App Added Successfully!!")

                } else alert(JSON.stringify('error'));
            })
            .catch((err) => alert(JSON.stringify(err)));
    };

    useEffect(() => {
        if(selectApp.length > 0 ) {
            setAppName(appsData.filter(rew => rew._id === selectApp)[0].appName)
            setProblemAddressed(appsData.filter(rew => rew._id === selectApp)[0].problemAddressed)
            setSolutionProvided(appsData.filter(rew => rew._id === selectApp)[0].solutionProvided)
            setDifferentiator(appsData.filter(rew => rew._id === selectApp)[0].differentiator)
            setCallToAction(appsData.filter(rew => rew._id === selectApp)[0].callToAction)
            setLogoUrl(appsData.filter(rew => rew._id === selectApp)[0].logoUrl)
            seTticketsUrl(appsData.filter(rew => rew._id === selectApp)[0].ticketsUrl)
            setSupportEmails(appsData.filter(rew => rew._id === selectApp)[0].supportEmails)
            setAlertEmails(appsData.filter(rew => rew._id === selectApp)[0].alertEmails)
            setSupportMobileNumbers(appsData.filter(rew => rew._id === selectApp)[0].supportMobileNumbers)
            setAlertMobileNumbers(appsData.filter(rew => rew._id === selectApp)[0].alertMobileNumbers)
            setResponsiblePersonId(appsData.filter(rew => rew._id === selectApp)[0].responsiblePersonId._id)
            setAccountablePersonId(appsData.filter(rew => rew._id === selectApp)[0].accountablePersonId._id)
            setConsultedPersonId(appsData.filter(rew => rew._id === selectApp)[0].consultedPersonId._id)
            setInformedPersonId(appsData.filter(rew => rew._id === selectApp)[0].informedPersonId._id)
            setResponsiblePersonName(appsData.filter(rew => rew._id === selectApp)[0].responsiblePersonId.name)
            setAccountablePersonName(appsData.filter(rew => rew._id === selectApp)[0].accountablePersonId.name)
            setConsultedPersonName(appsData.filter(rew => rew._id === selectApp)[0].consultedPersonId.name)
            setInformedPersonName(appsData.filter(rew => rew._id === selectApp)[0].informedPersonId.name)
            setResponsiblePersonMobile(appsData.filter(rew => rew._id === selectApp)[0].responsiblePersonId.mobile)
            setAccountablePersonMobile(appsData.filter(rew => rew._id === selectApp)[0].accountablePersonId.mobile)
            setConsultedPersonMobile(appsData.filter(rew => rew._id === selectApp)[0].consultedPersonId.mobile)
            setInformedPersonMobile(appsData.filter(rew => rew._id === selectApp)[0].informedPersonId.mobile)
            setResponsiblePersonEmail(appsData.filter(rew => rew._id === selectApp)[0].responsiblePersonId.email)
            setAccountablePersonEmail(appsData.filter(rew => rew._id === selectApp)[0].accountablePersonId.email)
            setConsultedPersonEmail(appsData.filter(rew => rew._id === selectApp)[0].consultedPersonId.email)
            setInformedPersonEmail(appsData.filter(rew => rew._id === selectApp)[0].informedPersonId.email)
            setActive(appsData.filter(rew => rew._id === selectApp)[0].active)

        }
    },[selectApp])




    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddSoftware(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1'>
                    <div class='add-software-1'>
                        <div class='add-software-header-1'>
                            <div id="add-software-name-1">
                                <span>Select App</span>
                            </div>
                            <button class='close-btn-1' onClick={() => props.closeAddSoftware(false)}>
                                <img id="add-software-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='software-names-1'>
                            <div id="software-name-header-1">
                                <span>App Name</span>
                            </div>
                            <select class='company-name-input-1' value={selectApp} onChange={(e) => setSelectApp(e.target.value)}>
                                {appsData.map((rec) =>
                                    <option value={rec._id}>
                                        {rec.appName}
                                    </option>
                                )}
                                <option value=''>
                                    Create New App Record
                                </option>
                            </select>
                        </div>
                        
                        <div class='swicth-active-1-software'>

                            <FormControlLabel control={<Switch
                                checked={active}
                                onChange={() => setActive(!active)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked />} label="Active" />

                        </div>
                        <div class='inputs-add-software-group'>
                            <div class='software-names-1'>
                                <div id="software-name-header-1">
                                    <span>App Name</span>
                                </div>
                                <input class='company-name-input-1' placeholder='App Name' value={appName} onChange={(e) => setAppName(e.target.value)} />
                            </div>
                            <div class='software-names-2'>
                                <div id="software-name-header-2">
                                    <span>Technical SPOC Name </span>
                                </div>
                                <input class='company-name-input-2' placeholder='Technical SPOC Name ' value={responsiblePersonName} onChange={(e) => setTechnicalSPOCName(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Technical SPOC Mobile</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Technical SPOC Mobile' value={responsiblePersonMobile} onChange={(e) => setTechnicalSPOCMobile(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Technical SPOC Email</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Technical SPOC Email' type='email' value={responsiblePersonEmail} onChange={(e) => setTechnicalSPOCEmail(e.target.value)} />
                            </div>

                            <div class='software-names-5'>
                                <div id="software-name-header-5">
                                    <span>Escalation SPOC Name </span>
                                </div>
                                <input class='company-name-input-5' placeholder='Escalation SPOC Name' value={accountablePersonName} onChange={(e) => setEscalationSPOCName(e.target.value)} />
                            </div>
                            <div class='software-names-6'>
                                <div id="software-name-header-6">
                                    <span>Escalation SPOC Mobile</span>
                                </div>
                                <input class='company-name-input-6' placeholder='Escalation SPOC Mobile' value={accountablePersonMobile} onChange={(e) => setEscalationSPOCMobile(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Escalation SPOC Email</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Escalation SPOC Email' type='email' value={accountablePersonEmail} onChange={(e) => setEscalationSPOCEmail(e.target.value)} />
                            </div>

                            <div class='software-names-5'>
                                <div id="software-name-header-5">
                                    <span>Logo Url </span>
                                </div>
                                <input class='company-name-input-5' placeholder='logoUrl' value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)} />
                            </div>
                            <div class='software-names-6'>
                                <div id="software-name-header-6">
                                    <span>Tickets Url</span>
                                </div>
                                <input class='company-name-input-6' placeholder='Tickets Url' value={ticketsUrl} onChange={(e) => seTticketsUrl(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Support Emails</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Support Emails' type='email' value={supportEmails} onChange={(e) => setSupportEmails(e.target.value)} />
                            </div>

                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Alert Emails</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Alert Emails' type='email' value={alertEmails} onChange={(e) => setAlertEmails(e.target.value)} />
                            </div>
                            <div class='software-names-5'>
                                <div id="software-name-header-5">
                                    <span>Consulted Person Name </span>
                                </div>
                                <input class='company-name-input-5' placeholder='Consulted Person  Name' value={consultedPersonId} onChange={(e) => setConsultedPersonId(e.target.value)} />
                            </div>
                            <div class='software-names-6'>
                                <div id="software-name-header-6">
                                    <span>Consulted Person Mobile</span>
                                </div>
                                <input class='company-name-input-6' placeholder='Consulted Person  Mobile' value={consultedPersonMobile} onChange={(e) => setConsultedPersonMobile(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Consulted Person Email</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Consulted Person Email' type='email' value={consultedPersonEmail} onChange={(e) => setConsultedPersonEmail(e.target.value)} />
                            </div>

                            <div class='software-names-5'>
                                <div id="software-name-header-5">
                                    <span>Informed Person Name </span>
                                </div>
                                <input class='company-name-input-5' placeholder='Informed Person Name' value={informedPersonId} onChange={(e) => setInformedPersonId(e.target.value)} />
                            </div>
                            <div class='software-names-6'>
                                <div id="software-name-header-6">
                                    <span>Informed Person Mobile</span>
                                </div>
                                <input class='company-name-input-6' placeholder='Informed Person Mobile' value={informedPersonMobile} onChange={(e) => setInformedPersonMobile(e.target.value)} />
                            </div>
                            <div class='software-names-3'>
                                <div id="software-name-header-3">
                                    <span>Informed Person Email</span>
                                </div>
                                <input class='company-name-input-3' placeholder='Informed Person Email' type='email' value={informedPersonEmail} onChange={(e) => setInformedPersonEmail(e.target.value)} />
                            </div>
                            <div class='software-names-text-1'>
                                <div id="software-name-header-text-1">
                                    <span>Problem Addressed</span>
                                </div>
                                <textarea class='company-name-input-text-1' placeholder='Problem Addressed' value={problemAddressed} onChange={(e) => setProblemAddressed(e.target.value)} />
                            </div>
                            <div class='software-names-text-1'>
                                <div id="software-name-header-text-1">
                                    <span>Solution Provided</span>
                                </div>
                                <textarea class='company-name-input-text-1' placeholder='Solution Provided' value={solutionProvided} onChange={(e) => setSolutionProvided(e.target.value)} />
                            </div>
                            <div class='software-names-text-1'>
                                <div id="software-name-header-text-1">
                                    <span>Differentiator</span>
                                </div>
                                <textarea class='company-name-input-text-1' placeholder='Differentiator' value={differentiator} onChange={(e) => setDifferentiator(e.target.value)} />
                            </div>
                            <div class='software-names-text-1'>
                                <div id="software-name-header-text-1">
                                    <span>Call To Action</span>
                                </div>
                                <textarea class='company-name-input-text-1' placeholder='Call To Action' value={callToAction} onChange={(e) => setCallToAction(e.target.value)} />
                            </div>




                            <div class='software-names-7'>
                                <div id="software-name-header-7">
                                    <span> Banner IMG </span>
                                </div>
                                <img id="company-name-input-7" src="../images/software.png" srcset="../images/software.png 1x" />

                                {/* <textarea class='company-name-input-6' placeholder='Escalation SPOC Mobile' /> */}
                            </div>
                        </div>
                        <button class='add-btn-software-1'>
                            Add
                        </button>
                    </div>
                    {/* <Button onClick={() => props.closeAddSoftware(false)}>Close</Button> */}
                </div>

            </Modal>
        </>
    )
}
export default Add_Software