import React from "react";
import './footer.css'

const Footer = () => {
    return(
        <div id="footer_app_pc_candi_r">
        <svg class="footer_vacan_r">
            <rect id="footer_vacan_r" rx="0" ry="0" x="0" y="0" width="100%" height="100%"></rect>
        </svg>
        <div class="footer-content">
            <div class="left-section">
                <a href="https://operate.live" target="_blank">
                    <div id="All_rights_reserved__2022_Oper_r">
                        <span>All rights reserved © 2023 Operate Live (OPC) Private Limited.</span>
                    </div>
                </a>
            </div>
            <div class="center-section">
                <div id="term_and_pol_grp_r">
                    <a href="https://jobs.bizibees.in/terms" target="_blank">
                        <div id="Terms_and_Conditions_lbl_vac_sa">
                            <span>Terms and Conditions</span>
                        </div>
                    </a>
                    <svg class="Line_2_vacan_sc" viewBox="0 0 1 24">
                        <path id="Line_2_vacan_sc" d="M 0 0 L 0 24"></path>
                    </svg>
                    <a href="https://jobs.bizibees.in/privacy" target="_blank">
                        <div id="Private_Policy_lbl_vac_sb">
                            <span>Private Policy</span>
                        </div>
                    </a>
                    
                </div>
            </div>
            <div class="right-section">
                <a href="https://www.linkedin.com/company/bizi-bees-outsourcing" target="_blank">
                    <img id="linkedin_vacan_r" src="../images/linkedin.png" srcset="../images/linkedin.png 1x, ../images/linkedin.png 2x" />
                </a>
                <a href="https://api.whatsapp.com/send?phone=919902247787" target="_blank">
                    <img id="whatapp_btn_img_vacan_r" src="../images/whatsapp.png" srcset="../images/whatsapp.png 1x, ../images/whatsapp.png 2x" />
                </a>
            </div>
        </div>
    </div>
    )
}
export default Footer