import React from "react";
import './package_Text.css'

const Packages_Text = () => {
    return (
        <div class='package-text'>
            <textarea placeholder="Problem Addressed" class="package-textarea-1" />

            <textarea placeholder="Solution Provided" class="package-textarea-2" />

            <textarea placeholder="Differentiator" class="package-textarea-3" />

            <textarea placeholder="Call To Action ( CTA )" class="package-textarea-4" />

            <button class='save-texts-1'>
                <img id="save-texts-img" src="../images/save.png" srcset="../images/save.png 1x" />

            </button>
        </div>
    )
}
export default Packages_Text