import React, { useContext, useEffect, useState } from "react";
import './software_Nav.css'
import Add_Software from "./Add_Software";
import { CompanyContext } from "./CompanyContext";
import { useLocation } from "react-router-dom";

const Software_Nav = (props) => {
    const [showAddSoftware, setShowAddSoftware] = useState("none")
    const [appsData, setAppsData] = useState([])
    const { company } = useContext(CompanyContext)
    const [selectedCardIndex, setSelectedCardIndex] = useState(0);
    const location = useLocation()
    // console.log(location.pathname)
    let companyID = company && company._id

    useEffect(() => {

        getApps()

    }, [company])



    const getApps = () => {
        // https://spapis.operate.live/company_apps/66880a3fe8407df20f314abb
        let apiURL = process.env.REACT_APP_API_KEY + "/company_apps";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setAppsData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    var appHeader = appsData.filter(rew => rew.companyId._id === companyID).map(item => item.appId);
    var appDetails = appsData.filter(rew => rew.companyId._id === companyID).map(item => item);

    useEffect(() => {
        if (appDetails.length > 0 && location.pathname === "/SoftwarePage") {
            props.appsDetails(appDetails[selectedCardIndex])
            // console.log(appIds2[selectedCardIndex], 'appIds2[appIndex]')
        }
    }, [selectedCardIndex, props, appDetails])


    return (
        <div id="Group_32">
            <div class='scrool-cards'>
                {appHeader.map((rec, index) =>
                    <div key={index} onClick={() => setSelectedCardIndex(index)} class='company-group' style={{ backgroundColor: selectedCardIndex === index ? 'red' : 'white', border: '1px solid black' }}>
                        <img id="bizibees_ci2x" src={appDetails.filter(rew => rew.appId._id === rec._id)[0].logoUrl || "../images/bizibees.png"}  />
                        <div id="Bizi_Bees">
                            <span>{rec.appName}</span>
                        </div>

                    </div>
                )}
            </div>

            <div id="Group_29" onClick={() => setShowAddSoftware("Add")}>
                <img id="add_cy" src="../images/add.png" srcset="../images/add.png 1x" />

                <div id="Add_New">
                    <span>Add App</span>
                </div>

            </div>

            <Add_Software display={showAddSoftware} closeAddSoftware={() => setShowAddSoftware("none")} />

        </div>
    )
}
export default Software_Nav