

import './add_Subscription.css'
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';

const Add_Subscription = (props) => {

    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddSubscription(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1'>
                    <div class='add-Subscription-1'>
                        <div class='add-Subscription-header-1'>
                            <div id="add-Subscription-name-1">
                                <span>Add Subscription</span>
                            </div>
                            <button class='close-btn-Subscription-1' onClick={() => props.closeAddSubscription(false)}>
                                <img id="add-Subscription-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='inputs-add-Subscription-group'>
                            <div class='Subscription-names-1'>
                                <div id="Subscription-name-header-1">
                                    <span>Package</span>
                                </div>
                                <input class='Subscription-name-input-1' placeholder='Package' />
                            </div>
                            <div class='Subscription-names-2'>
                                <div id="Subscription-name-header-2">
                                    <span>Subscription Start Date </span>
                                </div>
                                <input class='Subscription-name-input-2' type='date' />
                            </div>
                            <div class='Subscription-names-3'>
                                <div id="Subscription-name-header-3">
                                    <span>Subscription End Date</span>
                                </div>
                                <input class='Subscription-name-input-3' type='date' />
                            </div>
                            <div class='Subscription-names-4'>
                                <div id="Subscription-name-header-4">
                                    <span>Subscription Status</span>
                                </div>
                                {/* <input class='Subscription-name-input-4' placeholder='Escalation' /> */}
                                <select class="Subscription-name-input-4">
                                    <option value={'Database1'} label="Database1" />
                                    <option value={'Database2'} label="Database2" />
                                    <option value={'Database3'} label="Database3" />
                                    <option value={'Database4'} label="Database4" />

                                </select>
                            </div>
                            <div class='Subscription-names-5'>
                                <div id="Subscription-name-header-5">
                                    <span>Invoice Number </span>
                                </div>
                                <input class='Subscription-name-input-5' placeholder='Invoice Number' />
                            </div>
                            <div class='Subscription-names-6'>
                                <div id="Subscription-name-header-6">
                                    <span>Invoice Date</span>
                                </div>
                                <input class='Subscription-name-input-6' type='date' />
                            </div>
                            <div class='Subscription-names-7'>
                                <div id="Subscription-name-header-7">
                                    <span> Invoice Status </span>
                                </div>
                                <select class="Subscription-name-input-7">
                                    <option value={'Status'} label="Status" />
                                    <option value={'Status'} label="Status" />
                                    <option value={'Status'} label="Status" />
                                    <option value={'Status'} label="Status" />

                                </select>
                            </div>
                        </div>
                        <button class='add-btn-Subscription-1'>
                            Add
                        </button>
                    </div>
                </div>

            </Modal>
        </>
    )
}
export default Add_Subscription