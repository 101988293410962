import './add_Users.css'
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';

const Add_Users = (props) => {
    const [active, setActive] = useState(true)
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [personId, setPersonId] = useState("")
    const [rows, setRows] = useState([{ id: Date.now(), field1: '', field2: '' }]);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), field1: '', field2: '' }]);
    };

    const handleDeleteRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleInputChange = (id, event) => {
        const { name, value } = event.target;
        setRows(rows.map(row => (row.id === id ? { ...row, [name]: value } : row)));
    };

    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddUsers(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1'>
                    <div class='add-Users-1'>
                        <div class='add-Users-header-1'>
                            <div id="add-Users-name-1">
                                <span>Add Users</span>
                            </div>
                            <button class='close-btn-Users-1' onClick={() => props.closeAddUsers(false)}>
                                <img id="add-Users-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='inputs-add-Users-group'>
                            <div class='Users-names-1'>
                                <div id="Users-name-header-1">
                                    <span>Name</span>
                                </div>
                                <input class='Users-name-input-1' placeholder='Name' />
                            </div>
                            <div class='Users-names-2'>
                                <div id="Users-name-header-2">
                                    <span>Login ID </span>
                                </div>
                                <input class='Users-name-input-2' placeholder='Login ID' />
                            </div>
                            <div class='Users-names-5'>
                                <div id="Users-name-header-5">
                                    <span>Last Login </span>
                                </div>
                                <input class='Users-name-input-5' placeholder='Last Login' disabled={"disabled"} />
                            </div>



                            <div class='Users-names-7'>
                                <div id="Users-name-header-7">
                                    <span> Sections </span>
                                </div>
                                <div id="Users-name-header-7">
                                    <span> Access </span>
                                </div>
                                <div onClick={() => handleAddRow()}>
                                    <img id="add-Users-close-img-1" src="../images/add.png" srcset="../images/add.png 1x" />

                                </div>

                                {/* <select class="Users-name-input-7">
                                    <option value='5' label="Full Access" />
                                    <option value='3' label="Write Only" />
                                    <option value='1' label="Read Only" />

                                </select> */}
                            </div>
                            {rows.map((row, index) => (
                                <div class='Users-names-4' key={row.id} >

                                    <input type="text" class='Users-name-input-4' placeholder='Sections' />
                                    <input class='Users-name-input-4' placeholder='Access' />
                                    <div onClick={() => handleDeleteRow(row.id)}>
                                        <img id="add-Users-close-img-2" src="../images/minus.png" srcset="../images/minus.png 1x" />
                                    </div>

                                </div>
                            ))}
                        </div>
                        <button class='add-btn-Users-1'>
                            Add
                        </button>
                    </div>
                </div>

            </Modal>
        </>
    )
}
export default Add_Users