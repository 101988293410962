import React, { useContext, useState } from "react";
import './softwarePage.css'
import Header from "./Header";
import Footer from "./Footer";
import Software_Nav from "./Software_Nav";
import Software_Header from "./Software_Header";
import Software_Bottom from "./Software_Bottom";
import Software_Subscription from "./Software_Subscription";
import Software_Company from "./Software_Company";
import { useLocation } from "react-router-dom";
import { UserContext } from "../UserContext";
import { CompanyContext } from "./CompanyContext";

const SoftwarePage = () => {
    const [appsDetails, setAppsDetails] = useState({})

    return (
        <div id="Index">
            <Header />
            <Software_Nav appsDetails={(appsDetails) => setAppsDetails(appsDetails)} />
            <Software_Header />
            <div class='company-subscription'>
                <Software_Company appsDetails={appsDetails} />
                <Software_Subscription />
            </div>
            <Software_Bottom />

            <Footer />
        </div>
    )
}

export default SoftwarePage