import './add_FAQ.css'
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Radio, Switch } from '@mui/material';

const Add_FAQ = (props) => {
    const [active, setActive] = useState(true)
    const [selectedValue, setSelectedValue] = useState('Feature')
    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddFAQ(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1-FAQ'>
                    <div class='add-FAQ-1'>
                        <div class='add-FAQ-header-1'>
                            <div id="add-FAQ-name-1">
                                <span>Add FAQ</span>
                            </div>
                            <button class='close-btn-1' onClick={() => props.closeAddFAQ(false)}>
                                <img id="add-FAQ-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='swicth-active-1-FAQ'>

                            <FormControlLabel control={<Switch
                                checked={active}
                                onChange={() => setActive(!active)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked />} label="Active" />

                        </div>
                        <div class='inputs-add-FAQ-group'>
                            <div class='FAQ-names-1'>
                                <div id="FAQ-name-header-1">
                                    <span>Question</span>
                                </div>
                                <input class='add-FAQ-name-input-1' placeholder='Question' />
                            </div>
                            <div class='FAQ-names-2'>
                                <div id="FAQ-name-header-2">
                                    <span>Answer</span>
                                </div>
                                <textarea class='add-FAQ-name-input-2' placeholder='Answer' />
                            </div>
                            <div class='FAQ-names-3'>
                                <FormControlLabel value="Feature"
                                    control={<Radio
                                        checked={selectedValue === 'Feature'}
                                        onChange={() => setSelectedValue('Feature')}
                                        value="Feature"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />}
                                    label="Feature"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="Package"
                                    control={<Radio
                                        checked={selectedValue === 'Package'}
                                        onChange={() => setSelectedValue('Package')}
                                        value="Package"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />}
                                    label="Package"
                                    labelPlacement="start" />
                                <FormControlLabel
                                    value="General"
                                    control={<Radio
                                        checked={selectedValue === 'General'}
                                        onChange={() => setSelectedValue('General')}
                                        value="General"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'C' }}
                                    />}
                                    label="General"
                                    labelPlacement="start" />
                            </div>
                            <div class='FAQ-names-1'>
                                <div id="FAQ-name-header-1">
                                    <span>Select Feature</span>
                                </div>
                                <select class='add-FAQ-name-input-1'>
                                    <option>
                                    Select Feature
                                    </option>
                                </select>
                            </div>
                        </div>
                        <button class='add-btn-FAQ-1'>
                            Add
                        </button>
                    </div>
                    {/* <Button onClick={() => props.closeAddFAQ(false)}>Close</Button> */}
                </div>

            </Modal>
        </>
    )
}
export default Add_FAQ