import React from "react";
import './software_Subscription.css'

const Software_Subscription = () => {
    return (
        <div id="Group_30">
            <div id="Group_25">
                
                <div class='sub-inv'>
                    <div class='sub-inv-1'>
                        <div id="Subscription-1">
                            <span>Subscription</span>
                        </div>
                        <div id="Invoice-1">
                            <span>Invoice</span>
                        </div>
                    </div>
                    <div class='sub-inv-2'>
                        <div id="Package-1">
                            <span>Package</span>
                        </div>
                        <div id="StartDate-1">
                            <span> Start Date </span>
                        </div>

                        <div id="EndDate-1">
                            <span> End Date </span>
                        </div>
                        <div id="Status-1">
                            <span>Status</span>
                        </div>
                        <div id="Number-1">
                            <span> Number </span>
                        </div>
                        <div id="Date-1">
                            <span> Date </span>
                        </div>
                        <div id="Status-2">
                            <span>Status</span>
                        </div>

                    </div>
                </div>
                <div id="Scroll_Group_1">
                    <div class='sub-inv-3'>
                        <div id="Package-2">
                            <span>Package</span>
                        </div>
                        <div id="StartDate-2">
                            <span> Start Date </span>
                        </div>

                        <div id="EndDate-2">
                            <span> End Date </span>
                        </div>
                        <div id="Status-22">
                            <span>Status</span>
                        </div>
                        <div id="Number-2">
                            <span> Number </span>
                        </div>
                        <div id="Date-2">
                            <span> Date </span>
                        </div>
                        <div id="Status-3">
                            <span>Status</span>
                        </div>

                    </div>
                  
                </div>
            </div>

        </div>
    )
}
export default Software_Subscription