import React, { useContext, useEffect } from "react";
import './software_Company.css'
import { CompanyContext } from "./CompanyContext";

const Software_Company = (props) => {
    const { company } = useContext(CompanyContext)
    console.log(props.appsDetails, 'props appsDetails')

    useEffect(() => { }, [props])

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div id="Group_26">

            <div id="Group_23">
                <div id="Company_Name">
                    <span>Company Name</span>
                </div>
                <input class="Rectangle_9" value={company && company.companyName || ""} />


                <div id="Database">
                    <span>Database</span>
                </div>
                <select class="Rectangle_12">
                    <option value={'Database'} label="Database" />
                </select>
                {/* <div id="Group_19">
                    <div id="Connection_String">
                        <span>Connection String</span>
                    </div>

                </div> */}

                {/* <div id="Group_20">
                    <svg class="Rectangle_13">
                        <rect id="Rectangle_13" rx="7" ry="7" x="0" y="0" width="37" height="35">
                        </rect>
                    </svg>
                    <img id="checklist" src="../images/edit2.png" srcset="../images/edit2.png 1x" />

                </div> */}
                <div className="connectionstring-group-1">
                    <div id="Group_19">
                        <div id="Connection_String">
                            <span>Connection String</span>
                        </div>
                    </div>
                    <div class='input-edit-group-1'>
                        <input class="connection-string-input-1" value={props.appsDetails.dbConnectionString || ""} />
                        <img id="checklist-1-edit" src="../images/edit2.png" srcset="../images/edit2.png 1x" />
                    </div>
                </div>
                <div class='group-default-text-input-1'>

                    <div class='password-encription-text-group'>
                        <div id="Default_Password-1">
                            <span>Default Password</span>

                        </div>
                        <div id="Default_Password-1">
                            <span>Encryption Secret</span>

                        </div>
                    </div>
                    <div class='default-group-encrypt-inputs-1'>
                        <input class="default-input--passward-1" placeholder="Default Password" value={props.appsDetails.defaultUserPassword || ""} />

                        <input class="default-input--passward-1" placeholder="Encryption Secret" value={props.appsDetails.encryptionSecret || ""} />

                    </div>

                </div>
                <div class='urls-group-1'>
                    <div id="url-text-1">
                        <span>App Url</span>

                    </div>
                    <input class="url-input-1" value={props.appsDetails.appUrl || ""} />
                    <div onClick={() => openInNewTab(props.appsDetails.appUrl)}>
                        <img id="worlwide-1" src="../images/worldwide.png" srcset="../images/worldwide.png 1x" />
                    </div>
                </div>
                <div class='urls-group-2'>
                    <div id="url-text-1">
                        <span>Apis Url</span>

                    </div>
                    <input class="url-input-1" value={props.appsDetails.apisUrl || ""} />
                    <div onClick={() => openInNewTab(props.appsDetails.apisUrl)}>
                        <img id="worlwide-1" src="../images/worldwide.png" srcset="../images/worldwide.png 1x" />
                    </div>
                </div>
                <div class='urls-group-2'>
                    <div id="url-text-1">
                        <span>Logo Url</span>

                    </div>
                    <input class="url-input-1" value={props.appsDetails.logoUrl || ""} />

                    <div onClick={() => openInNewTab(props.appsDetails.logoUrl)}>
                        <img id="worlwide-1" src="../images/worldwide.png" srcset="../images/worldwide.png 1x" />
                    </div>
                </div>
                <div class='urls-group-2'>
                    <div id="url-text-1">
                        <span>TicketsUrl</span>

                    </div>
                    <input class="url-input-1" value={props.appsDetails.ticketsUrl || ""} />
                    <div onClick={() => openInNewTab(props.appsDetails.ticketsUrl)}>
                        <img id="worlwide-1" src="../images/worldwide.png" srcset="../images/worldwide.png 1x" />
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Software_Company