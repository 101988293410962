// import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Login from './Components/Login';
import {
  Link,
  Route,
  Routes
} from 'react-router-dom';
import SoftwarePage from './Components/SoftwarePage';
import Packages from './Components/Packages';
import FAQ from './Components/FAQ';
import Ticket from './Components/Ticket';
import { useEffect, useMemo, useState } from 'react';
import { UserContext } from './UserContext';
import { CompanyContext } from './Components/CompanyContext';

function App() {
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);


  useEffect(() => {

    let storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }

    let storedCompany = localStorage.getItem('companyData');
    console.log(JSON.parse(storedCompany));
    if (storedCompany) {
      setCompany(JSON.parse(storedCompany))
    }
  }, []);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  const currentompany = useMemo(() => ({ company, setCompany }), [company, setCompany]);

  return (
    <>
      <UserContext.Provider value={value}>
        <CompanyContext.Provider value={currentompany}>

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/SoftwarePage" element={<SoftwarePage />} />
            <Route path="/Packages" element={<Packages />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Ticket" element={<Ticket />} />
          </Routes>
        </CompanyContext.Provider>

      </UserContext.Provider>

    </>
  );
}

export default App;
