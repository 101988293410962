import React from "react";

const UAE_States = (props) => {
    return (
        <div>
            <select class="add-company-name-input-6" value={props.companyState} onChange={(e) => props.setCompanyState(e.target.value)}>
                <option value="Abu Dhabi">Abu Dhabi</option>
                <option value="Dubai">Dubai </option>
                <option value="Sharjah">Sharjah </option>
                <option value="Ajman">Ajman</option>
                <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                <option value="Fujairah">Fujairah</option>
                <option value="Ras Al Khaimah">Ras Al Khaimah</option>

                
            </select>

        </div>
    )
}

export default UAE_States