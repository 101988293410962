import './add_Features.css'
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch } from '@mui/material';

const Add_Features = (props) => {
    const [active, setActive] = useState(true)
    const [appId, setAppId] = useState("")
    const [feature, setFeature] = useState("")
    const [advantage, setAdvantage] = useState("")
    const [benefit, setBenefit] = useState("")
    const [description, setDescription] = useState("")
    const [appsData, setAppsData] = useState([])


    useEffect(() => {
        if (props.display === "Edit") {
            setAppId(props.data ? props.data.appId : "")
            setFeature(props.data ? props.data.feature : "")
            setAdvantage(props.data ? props.data.advantage : "")
            setBenefit(props.data ? props.data.benefit : "")
            setDescription(props.data ? props.data.description : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.display])

    useEffect(() => {
        getApps()
    }, [props])

    const getApps = () => {

        let apiURL = process.env.REACT_APP_API_KEY + "/apps";
        let apiParams = {
            method: "GET",
            // headers: {
            //     authtoken: await AsyncStorage.getItem("loggedintoken")

            // },
        };
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setAppsData(repos.data)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
    }

    const addFeature = () => {
        // if (email.length === 0) {
        //     return alert("Please Enter Email!!");
        // }

        const apiUrl = process.env.REACT_APP_API_KEY + '/features';
        var apiParam = {
            method: 'POST',
            headers: {
                appId: appId,
                feature: feature,
                advantage: encodeURI(advantage),
                benefit: encodeURI(benefit),
                description: encodeURI(description),
                active: active,
            },
        };

        // console.log(apiUrl, apiParam, 'apiUrl', 'apiParam')

        fetch(apiUrl, apiParam)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {

                if (repos.data) {
                    props.closeAddFeatures(false)
                    alert("Feature Added Successfully!!")

                } else alert(JSON.stringify('error'));
            })
            .catch((err) => alert(JSON.stringify(err)));
    };




    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddFeatures(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1'>
                    <div class='add-Features-1'>
                        <div class='add-Features-header-1'>
                            <div id="add-Features-name-1">
                                <span>{props.display} Features</span>
                            </div>
                            <button class='close-btn-1' onClick={() => props.closeAddFeatures(false)}>
                                <img id="add-Features-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='swicth-active-1-Features'>

                            <FormControlLabel control={<Switch
                                checked={active}
                                onChange={() => setActive(!active)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked />} label="Active" />

                        </div>
                        <div class='inputs-add-Features-group'>
                            <div class='Features-names-1'>
                                <div id="Features-name-header-1">
                                    <span>App Name</span>
                                </div>
                                {/* <input class='add-Features-name-input-1' placeholder='App Name' /> */}
                                <select class='add-Features-name-input-1' value={appId} onChange={(e) => setAppId(e.target.value)}>
                                    <option value=''>
                                        Select
                                    </option>
                                    {appsData.map((rec) =>
                                        <option value={rec._id}>
                                            {rec.appName}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div class='Features-names-1'>
                                <div id="Features-name-header-1">
                                    <span>Feature</span>
                                </div>
                                <input class='add-Features-name-input-1' placeholder='Feature' value={feature} onChange={(e) => setFeature(e.target.value)} />
                            </div>
                            <div class='Features-names-1'>
                                <div id="Features-name-header-1">
                                    <span>Show After</span>
                                </div>
                                <select class='add-Features-name-input-1' >
                                    <option value=''>
                                        Select
                                    </option>
                                </select>
                            </div>
                            <div class='Features-names-text-1'>
                                <div id="Features-name-header-text-1">
                                    <span>Description </span>
                                </div>
                                <textarea class='add-Features-name-input-text-1' placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div class='advantage-benifit-group-1'>
                                <div id="Features-name-header-3">
                                    <span>Benefit</span>
                                </div>
                                <div id="Features-name-header-2">
                                    <span>Advantage</span>
                                </div>
                                <img id="add-Features-add-img-1" src="../images/add3.png" srcset="../images/add3.png 1x" />

                            </div>
                            <div class='Features-names-group-1'>
                                <textarea class='add-Features-advantage-input-1' placeholder='Benefit' value={benefit} onChange={(e) => setBenefit(e.target.value)} />

                                <textarea class='add-Features-benifit-input-1' placeholder='Advantage' value={advantage} onChange={(e) => setAdvantage(e.target.value)} />
                                <div class='img-save-delete-group'>
                                    <img id="add-Features-save-img-1" src="../images/save.png" srcset="../images/save.png 1x" />
                                    <img id="add-Features-delete-img-1" src="../images/delete.png" srcset="../images/delete.png 1x" />
                                </div>
                            </div>

                        </div>
                        <button class='add-btn-Features-1' onClick={() => addFeature()}>
                            {props.display}
                        </button>
                    </div>
                    {/* <Button onClick={() => props.closeAddFeatures(false)}>Close</Button> */}
                </div>

            </Modal>
        </>
    )
}
export default Add_Features