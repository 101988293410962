import React from "react";
import Indian_States from "./Country_States/Indian_States";
import UAE_States from "./Country_States/UAE_States";
import Saudi_States from "./Country_States/Saudi_States";


const Display_Country_States = (props) => {
    const countryControll = () => {
        switch (props.country) {
            case 'India':
                return <Indian_States companyState={props.companyState} setCompanyState={(newText) => props.setCompanyState(newText)} />
            case 'United Arab Emirates':
                return <UAE_States companyState={props.companyState} setCompanyState={(newText) => props.setCompanyState(newText)} />
            case 'Saudi Arabia':
                return <Saudi_States companyState={props.companyState} setCompanyState={(newText) => props.setCompanyState(newText)} />

            default:
                return <input class="add-company-name-input-6" placeholder="State" value={props.companyState} onChange={(e) => props.setCompanyState(e.target.value)} />
                    ;
        }
    }
    return (countryControll()
    )
}

export default Display_Country_States