import React, { useState } from "react";
import './software_Header.css'
import { Link, useLocation } from "react-router-dom";
import Add_Subscription from "./Add_Subscription";

const Software_Header = () => {
    const [showAddSubscription, setShowAddSubscription] = useState('none')
    const location = useLocation()
    console.log(location, 'location')
    return (
        <div id="Group_10">
            <Link to={'/Home'}>

                <img id="angle-left_1" src="../images/back.png" srcset="../images/back.png 1x" />
            </Link>

            <Link to={'/SoftwarePage'}>

                <div id="Group_8" style={{ backgroundColor: location.pathname === "/SoftwarePage" ? "#40e83a" : "white" }}>
                    <img id="office-building" src="../images/company.png" srcset="../images/company.png 1x" />

                    <div id="Group_4">
                        <div id="Companies" style={{ color: location.pathname === "/SoftwarePage" ? "white" : "black" }}>
                            <span>Companies</span>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to={'/Packages'}>
                <div id="Group_7" style={{ backgroundColor: location.pathname === "/Packages" ? "#40e83a" : "white" }}>
                    <img id="answer" src="../images/choice.png" srcset="../images/choice.png 1x" />

                    <div id="Group_3">
                        <div id="Packages" style={{ color: location.pathname === "/Packages" ? "white" : "black" }}>
                            <span>Packages</span>
                        </div>

                    </div>
                </div>
            </Link>
            <Link to={'/FAQ'}>

                <div id="Group_6" style={{ backgroundColor: location.pathname === "/FAQ" ? "#40e83a" : "white" }}>
                    <img id="question-mark" src="../images/conversation.png" srcset="../images/conversation.png 1x" />

                    <div id="Group_1">
                        <div id="FAQs" style={{ color: location.pathname === "/FAQ" ? "white" : "black" }}>
                            <span>FAQ's</span>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to={'/Ticket'} >
                <div id="Group_5" style={{ backgroundColor: location.pathname === "/Ticket" ? "#40e83a" : "white" }}>
                    <img id="alert" src="../images/alert.png" srcset="../images/alert.png 1x" />

                    <div id="Group_2">
                        <div id="Tickets" style={{ color: location.pathname === "/Ticket" ? "white" : "black" }}>
                            <span>Tickets</span>
                        </div>

                    </div>
                </div>
            </Link>

            <div id="Group_16" onClick={() => setShowAddSubscription('Add')}>

                <img id="add" src="../images/add2.png" srcset="../images/add2.png 1x" />

            </div>
            <Add_Subscription display={showAddSubscription} closeAddSubscription={() => setShowAddSubscription('none')} />
        </div>
    )
}
export default Software_Header