import './add_Ticket.css'
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch } from '@mui/material';

const Add_Ticket = (props) => {
    const [active, setActive] = useState(true)

    return (
        <>
            <Modal
                open={props.display !== 'none'}
                onClose={() => props.closeAddTicket(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div class='modal-1-ticket'>
                    <div class='add-Ticket-1'>
                        <div class='add-Ticket-header-1'>
                            <div id="add-Ticket-name-1">
                                <span>Add Ticket</span>
                            </div>
                            <button class='close-btn-1-ticket' onClick={() => props.closeAddTicket(false)}>
                                <img id="add-Ticket-close-img-1" src="../images/close2.png" srcset="../images/close2.png 1x" />
                            </button>
                        </div>
                        <div class='swicth-active-1-Ticket'>

                            <FormControlLabel control={<Switch
                                checked={active}
                                onChange={() => setActive(!active)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked />} label="Active" />

                        </div>
                        <div class='inputs-add-Ticket-group'>
                            <div class='Ticket-names-1'>
                                <div id="Ticket-name-header-1">
                                    <span>Subject</span>
                                </div>
                                <select class='add-Ticket-name-input-1'>
                                    <option value={'0'}>Subject</option>

                                </select>
                            </div>
                            <div class='Ticket-names-2'>
                                <div id="Ticket-name-header-2">
                                    <span>Type</span>
                                </div>
                                <input class='add-Ticket-name-input-2' placeholder='Type' />
                            </div>

                            <div class='Ticket-names-3'>
                                <div id="Ticket-name-header-3">
                                    <span>Number</span>
                                </div>
                                <input class='add-Ticket-name-input-3' placeholder='Number' />
                            </div>
                            <div class='Ticket-names-1'>
                                <div id="Ticket-name-header-1">
                                    <span>Title</span>
                                </div>
                                <select class='add-Ticket-name-input-1'>
                                    <option value={'0'}>Title</option>


                                </select>
                            </div>
                            <div class='Ticket-names-6'>
                                <div id="Ticket-name-header-6">
                                    <span>Description</span>
                                </div>
                                <textarea class='add-Ticket-name-input-6' placeholder='Description' />
                            </div>
                            <div class='Ticket-names-1'>
                                <div id="Ticket-name-header-1">
                                    <span>Raised By</span>
                                </div>
                                <select class='add-Ticket-name-input-1'>
                                    <option value={'0'}>Raised By</option>


                                </select>
                            </div>
                            <div class='Ticket-names-3'>
                                <div id="Ticket-name-header-3">
                                    <span>Raised Time</span>
                                </div>
                                <input class='add-Ticket-name-input-3' placeholder='Raised Time' type='datetime-local' />
                            </div>
                            <div class='Ticket-names-1'>
                                <div id="Ticket-name-header-1">
                                    <span>Assigned To</span>
                                </div>
                                <select class='add-Ticket-name-input-1'>
                                    <option value={'0'}>Assigned To</option>


                                </select>
                            </div>
                            <div class='dob-gender-group'>
                                <div class='Ticket-names-4'>
                                    <div id="Ticket-name-header-4">
                                        <span>Priority </span>
                                    </div>
                                    <select class='add-Ticket-name-input-4'>
                                        <option value={'0'}>Priority</option>

                                    </select>
                                </div>
                                <div class='Ticket-names-4'>
                                    <div id="Ticket-name-header-4">
                                        <span>Impact </span>
                                    </div>
                                    <select class='add-Ticket-name-input-4'>
                                        <option value={'0'}>Impact</option>

                                    </select>
                                </div>
                            </div>
                            <div class='Ticket-names-1'>
                                <div id="Ticket-name-header-1">
                                    <span>Status</span>
                                </div>
                                <select class='add-Ticket-name-input-1'>
                                    <option value={'0'}>Status</option>


                                </select>
                            </div>
                            
                            <div class='Ticket-names-13'>
                                <div id="Ticket-name-header-13">
                                    <span>Comments</span>
                                </div>
                                <textarea class='add-Ticket-name-input-13' placeholder='Comments' />
                            </div>
                            <div class='Ticket-names-13'>
                                <div id="Ticket-name-header-13">
                                    <span>History</span>
                                </div>
                                <textarea class='add-Ticket-name-input-13' placeholder='History' />
                            </div>

                        </div>
                        <button class='add-btn-Ticket-1'>
                            Add
                        </button>
                    </div>
                    {/* <Button onClick={() => props.closeAddTicket(false)}>Close</Button> */}
                </div>

            </Modal>
        </>
    )
}
export default Add_Ticket